<template>
  <vue-modal @close="$emit('close')" ref="modal" size="md" class="vue-modal-dialog-dog">
    <template #title><h2><i class="fa-solid fa-shield-dog"></i> Perros de {{itemCopy?.nombre?.split(' ').map(x =>( x[0]?.toUpperCase() || '') +( x.substring(1)?.toLowerCase() || '')).join(' ')}}</h2></template>
    <template #body>
      <DogsComponent class="h-dogs-modal" @sendIndexMacho="sendIndexMacho" @sendIndexHembra="sendIndexHembra"/>
      <MyDogModal
        v-if="showModal"
        @close="closeModal"
        :item="selItem"
        :owner="selOwner"
      />
    </template>
    <template #footer>
      <button class="btn btn-primary" type="submit" @click="close">Cerrar</button>
    </template>
  </vue-modal>  
</template>

<script>
import MyDogModal from "./MyDogModal.vue";
import DogsComponent from "./DogsComponent.vue"
export default {
  name: 'PersonaDogsTable',
  components:{
    MyDogModal,
    DogsComponent,
  },
  data: ()=>({
    itemCopy: null,
    selOwner: null,
    selItem: null,
    showModal: null,
  }),
   props: {
    item: {
      type: Object,
      default: null,
    },
  },
  computed: {
    machos(){
      return this.$store.getters.canesM
    },
    hembras(){
      return this.$store.getters.canesF
    }
  },
  async beforeMount() {
    if(this.item){
      this.itemCopy = {...this.item}
      await this.getMacho(this.itemCopy.id);
      await this.getHembra(this.itemCopy.id);
    } 
   
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    closeModal() {
      this.selOwner = null;
      this.selItem = null;
      this.showModal = null;
    },
    getMacho(id){
      const params = {sexo: "M"}
      this.$store.dispatch("getMachos", {id, params});
    },
    getHembra(id){ 
      const params = {sexo: "H"}
      this.$store.dispatch("getHembras",  {id, params});
    },
    async sendIndexMacho($event,index){
      this.selOwner = this.itemCopy;
      this.selItem = this.machos[index];
      await this.obtenerPadres(this.selItem.id_can)
      this.showModal = true;
    },
    async sendIndexHembra($event,index){
      this.selOwner = this.itemCopy;
      this.selItem = this.hembras[index];
      await this.obtenerPadres(this.selItem.id_can)
      this.showModal = true;
    },
    obtenerPadres(id){
      this.$store.dispatch("getPadres", id);
    },
  },
};
</script>

<style>
.h-dogs-modal{
  height: 50vh !important;
}
</style>