<template>
  <vue-modal @close="$emit('close')" ref="modal" size="md" class="vue-modal-dialog-dog">
    <template #title><i class="fa-solid fa-shield-dog"></i> Ficha de {{itemCopy?.nombre.charAt(0) + itemCopy?.nombre.slice(1).toLowerCase()}}</template>
    <template #body>
      <div class="d-flex align-items-center justify-content-between flex-direction-column">
        <div class="d-flex flex-column justify-content-center">
          <small>
            <FormText
              class="text-primary"
              label="ID"
              icon="fa-solid fa-hashtag"
              v-model="itemCopy.id_can"
              disabled
            />
          </small>
          <img :src="fotoPerfilDog" class="pic-perfil-dog" alt="Foto Perfil" />
        </div>
        <div>
          <template v-if="nombreApellido">
              <FormText
                class="text-primary"
                label="Nombre"
                icon="fa-solid fa-dog"
                v-model="nombreApellido"
                disabled
              />
            </template>
            <template v-else>
              <FormText
                class="text-primary"
                label="Nombre"
                icon="fa-solid fa-dog"
                v-model="itemCopy.nombre"
                disabled
              />
            </template>
          <FormText
            class="text-primary"
            label="Criadero"
            icon="fa-solid fa-building-flag"
            v-model="itemCopy.id_cli_criadero"
            disabled
          />
        </div>
        <div v-if="ownerCopy.first_name">
          <FormText
            class="text-primary"
            label="Nombre"
            icon="fa-solid fa-user"
            v-model="ownerCopy.first_name"
            disabled
          />
          <FormText
            class="text-primary"
            label="Apellido"
            icon="fa-solid fa-user"
            v-model="ownerCopy.last_name"
            disabled
          />
        </div>
        <div v-else>
          <FormText
          class="text-primary"
          label="Nombre"
          icon="fa-solid fa-user"
          v-model="ownerCopy.nombre"
          disabled
        /> 
        </div>
        <img :src="fotoPerfil" class="pic-perfil-user" alt="Foto Perfil"/> 
      </div>
      <div class="d-flex align-items-center justify-content-between mt-1">
        <table class="table text-center">
            <thead>
              <tr>
                <th class="text-primary" scope="col">
                  Sel
                </th>
                <th class="text-primary" scope="col">
                  DFC
                </th>
                <th class="text-primary" scope="col">
                  Codo
                </th>
                <th class="text-primary" scope="col">
                  Servicios
                </th>
                <th class="text-primary" scope="col">
                  Crias
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  <template v-if="itemCopy?.seleccion_clase">
                    <i class="fa-solid fa-star text-primary"></i>
                  </template>
                  <template v-else>
                    -
                  </template>
                </th>
                <td>
                  {{ itemCopy?.dcf_grado || "-"}}
                  -
                  {{ itemCopy?.dcf_calificacion || "-"}}
                </td>
                <td >
                  {{ itemCopy?.dcfcodo_calificacion || "-"}}
                  -

                </td>
                <td >
                  {{ itemCopy?.servicios_fertiles || "-"}}
                </td>
                <td >
                  {{ itemCopy?.crias_registradas || "-"}}
                  -
                  {{ itemCopy?.crias_permitidas || "-"}}
                </td> 
              </tr>
            </tbody>
          </table>
      </div>
      <div class="d-flex align-items-center justify-content-between flex-direction-column-reverse">
        <div class="d-flex flex-column">
          <div class="card">
            <div class="card-body">
              <template v-if="itemCopy.padre_nombre">
                <div class="card-body pl-2">
                  <p class="text-primary"><i class="fa-solid fa-dog"></i> Nombre Padre</p>
                  {{itemCopy.padre_nombre}}
                </div>
              </template>
              <template v-else>
                <FormText
                  class="text-primary"
                  label="Padre"
                  icon="fa-solid fa-dog"
                  v-model="itemCopy.id_can_padre"
                  disabled
                /> 
              </template>
              <div class="card-body text-center">
                <small>
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="text-primary" scope="col">
                          Sel
                        </th>
                        <th class="text-primary" scope="col">
                          DFC
                        </th>
                        <th class="text-primary" scope="col">
                          Codo
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th  scope="row">
                          <template v-if="padreDog?.seleccion_clase">
                            <i class="fa-solid fa-star text-primary"></i>
                          </template>
                          <template v-else>
                            -
                          </template>
                        </th>
                        <td>
                          {{ padreDog?.dcf_calificacion || "-"}}
                        </td>
                        <td>
                          {{ padreDog?.dcfcodo_calificacion|| "-"}}
                        </td> 
                      </tr>
                    </tbody>
                  </table>
                </small>
              </div>
            </div>
          </div>
          <div class="card mt-2">
            <div class="card-body">
              <template v-if="itemCopy.madre_nombre">
                <div class="card-body pl-2">
                  <p class="text-primary"><i class="fa-solid fa-dog"></i> Nombre Madre</p>
                  {{itemCopy.madre_nombre}}
                </div>
              </template>
              <template v-else>
                <FormText
                  class="text-primary"
                  label="Madre"
                  icon="fa-solid fa-dog"
                  v-model="itemCopy.id_can_madre"
                  disabled
                /> 
              </template>
              <div class="card-body text-center">
                <small>
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="text-primary" scope="col">
                          Sel
                        </th>
                        <th class="text-primary" scope="col">
                          DFC
                        </th>
                        <th class="text-primary" scope="col">
                          Codo
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th  scope="row">
                         <template v-if="madreDog?.seleccion_clase ">
                          <i class="fa-solid fa-star text-primary"></i>
                        </template>
                        <template v-else>
                          -
                        </template>
                        </th>
                        <td>
                          {{ madreDog?.dcf_calificacion || "-"}}
                        </td>
                        <td >
                          {{ madreDog?.dcfcodo_calificacion || "-"}}
                        </td> 
                      </tr>
                    </tbody>
                  </table>
                </small>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex row align-items-center justify-content-center ml-2">
            <template v-if="itemCopy.sexo === 'M'">
                <FormTextString
                  class="text-primary"
                  label="Sexo"
                  icon="fa-solid fa-mars"
                  value="Macho"
                  disabled
                />
              </template>
              <template v-else>
                <FormTextString
                  class="text-primary"
                  label="Sexo"
                  icon="fa-solid fa-venus"
                  value="Hembra"
                  disabled
                />
              </template>
              <template v-if="itemCopy.peludo === true">
                <FormTextString
                  class="text-primary"
                  label="Clase de Pelo"
                  icon="fa-solid fa-arrow-down-wide-short"
                  value="Pelo Largo"
                  disabled
                />
              </template>
              <template v-else>
                <FormTextString
                  class="text-primary"
                  label="Clase de Pelo"
                  icon="fa-solid fa-arrow-down-wide-short"
                  value="Pelo Corto"
                  disabled
                />
              </template>
              <FormText
                class="text-primary"
                label="Color"
                icon="fa-solid fa-eye-dropper"
                v-model="itemCopy.id_color"
                disabled
              />
              <FormText
                class="text-primary"
                label="Señas"
                icon="fa-solid fa-eye"
                v-model="itemCopy.id_senias"
                disabled
              />
              <FormText
                class="text-primary"
                label="Tatuaje / chip"
                icon="fa-solid fa-microchip"
                v-model="itemCopy.dn_nro"
                disabled
              />
              <FormDate
                class="text-primary"
                label="Nacimiento"
                icon="fa-solid fa-cake-candles"
                v-model="itemCopy.nacimiento"
                disabled
              />
            <div class="card-body">
              <div class="card-body text-center mt-2">
                <h6>Datos Sobre la lechigada</h6>
              </div>
              <small>
                <div class="d-flex row align-items-center justify-content-center"> 
                  <FormText
                    class="text-primary"
                    label="Nacidos"
                    icon="fa-solid fa-bone"
                    v-model="itemCopy.nacidos"
                    disabled
                  />
                  <FormText
                    class="text-primary"
                    label="Muertes"
                    icon="fa-solid fa-skull-crossbones"
                    v-model="itemCopy.muertes"
                    disabled
                  />
                  <FormText
                    class="text-primary"
                    label="Sacrificados"
                    icon="fa-solid fa-syringe"
                    v-model="itemCopy.sacrificados"
                    disabled
                  />
                  <FormText
                    class="text-primary"
                    label="Inscriptos"
                    icon="fa-solid fa-house-signal"
                    v-model="itemCopy.inscriptos"
                    disabled
                  />
                </div> 
              </small>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column align-items-center mt-3">
          <h6>Inscripto en el Registro Genealogico de POA</h6>
          <div class="row justify-content-center ">
            <FormText
              class="text-primary"
              label="Numero"
              icon="fa-solid fa-hashtag"
              v-model="itemCopy.nro_transferencia"
              disabled
          />
          <FormDate
              class="text-primary"
              label="Fecha"
              icon="fa-solid fa-calendar"
              v-model="itemCopy.fecha_agrupacion_compite"
              disabled
          />
          </div>
        </div>  
    </template>
    <template #footer>
      <small class="row flex-nowrap no-mr-6 mr-6">
              <button class="btn btn-info mr-2 mt1 disabled" type="submit" @click="close">Pedrigree</button>
              <button class="btn btn-secondary mr-2 mt1 disabled" type="submit" @click="close">Crias</button>
              <button class="btn btn-warning mt1 disabled" type="submit" @click="close">Torneos</button>
            </small> 
      <button class="btn btn-primary" type="submit" @click="close">Cerrar</button>
    </template>
  </vue-modal>
</template>
<script>
import FormText from "@/components/Form/FormText.vue";
import FormTextString from "@/components/Form/FormTextString.vue";
import FormDate from "@/components/Form/FormDate.vue";
export default {
  components: {
    FormText,
    FormDate,
    FormTextString,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    owner:{
      type: Object,
      default: null,
    }
  },
  data: () => ({
    itemCopy: null,
    ownerCopy: null,
  }),
  mounted() {
    if (this.item) this.itemCopy = {...this.item}
    if (this.owner) this.ownerCopy = {...this.owner}
  },
  computed:{
    padreDog(){
      const padre =this.$store.getters.padresDogs?.find(x=>x.sexo === 'M')
      if (!padre) return {}
      return padre
    },
    madreDog(){
      const madre =this.$store.getters.padresDogs?.find(x=>x.sexo === 'H')
      if (!madre) return {}
      return madre
    },
    fotoPerfil(){
      if(this.$store.getters.user?.picPerfil) {
        return this.$store.getters.user.picPerfil
      } else {
        return "/img/perfil.svg"
      }
    },
    nombreApellido(){
      if(this.itemCopy.nombre && this.itemCopy.apellido) {return `${this.itemCopy.nombre} ${this.itemCopy.apellido}`}
      return null
    },
    fotoPerfilDog(){
      if(this.itemCopy?.picPerfil) {
        return this.itemCopy.picPerfil
      } else {
        return "/img/dogperfil2.png"
      }
    },
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>
<style>
.pic-perfil-dog{
  max-width: auto;
  max-height: 100px;
  border-radius: 25%;
}
.pic-perfil-user{
  max-height: 100px;
  border-radius: 50%;
}
@media (max-width: 539px) {
  .vue-modal-dialog-dog{
    min-width: 434px !important;
  }
  .flex-direction-column-reverse{
    flex-direction: column-reverse;
    justify-content: center
  }
  .flex-direction-column{
    flex-direction: column;
    margin-bottom: 1em;
  }
  .mt1{
    margin-top: 1em;
  }
  .no-mr-6{
    margin-right: 1em !important;
  }
}
</style>
