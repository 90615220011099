<template>
  <!--
  <template v-if="CheckRoles.Socio === this.$store.getters.user.role_id">
    <DashboardUser/>
  </template>
  <template v-else>
    <DashboardDelegacion/>
  </template>-->

<!--TODO: Desplegar un dashboard distinto para cada tipo de user. Lo desactive por ahora
para ahorrar tiempo. Firma: Yo, el martinez.-->


<div>
  <DashboardUser/>
</div>

</template>
<script>
/*eslint-disable*/
import {CheckRoles} from "@/acl/constants.js"
import DashboardUser from "./DashboardUser.vue"
import DashboardDelegacion from "./DashboardDelegacion.vue"

export default {
  components: {
    DashboardUser,
    DashboardDelegacion,
  },
  computed:{
    CheckRoles(){
      return CheckRoles
    },
  }
};
</script>

<style>
</style>
