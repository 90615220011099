<template>
  <div class="card w-100">
    <div class="card-body">
      <h2><i class="fa-solid fa-shield-dog"></i> Mis Perros</h2>
    </div>
  <DogsComponent class="h-dogs" @sendIndexMacho="sendIndexMacho" @sendIndexHembra="sendIndexHembra"/>
  </div>
  <MyDogModal
    v-if="showModal"
    @close="closeModal"
    :item="selItem"
    :owner="selOwner"
  />
</template>

<script>
import MyDogModal from "./MyDogModal.vue";
import DogsComponent from "./DogsComponent.vue"
export default {
  components:{
    MyDogModal,
    DogsComponent
  },
  name: "myDogsTable",
  data: ()=>({
    selOwner: null,
    selItem: null,
    showModal: null,
  }),
  computed: {
    machos(){
      return this.$store.getters.canesM
    },
    hembras(){
      return this.$store.getters.canesF
    }
  },
  async beforeMount() {
    if(this.$store.getters.user){
      await this.getMacho();
      await this.getHembra();
    } 
  },
  methods: {
    closeModal() {
      this.selOwner = null;
      this.selItem = null;
      this.showModal = null;
    },
    getMacho(){
      const id = this.$store.getters.user?.persona_id
      const params = {sexo: "M"}
      this.$store.dispatch("getMachos", {id, params});
    },
    getHembra(){
      const id = this.$store.getters.user?.persona_id
      const params = {sexo: "H"}
      this.$store.dispatch("getHembras",  {id, params});
    },
    async sendIndexMacho($event,index){
      this.selOwner = this.$store.getters.user;
      this.selItem = this.machos[index];
      await this.obtenerPadres(this.selItem.id_can)
      this.showModal = true;
    },
    async sendIndexHembra($event,index){
      this.selOwner = this.$store.getters.user;
      this.selItem = this.hembras[index];
      await this.obtenerPadres(this.selItem.id_can)
      this.showModal = true;
    },
    obtenerPadres(id){
      this.$store.dispatch("getPadres", id);
    },
  },
};
</script>

<style>

</style>