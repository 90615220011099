<template>
  <vue-modal @close="$emit('close')" ref="modal" size="md">
    <template #title>Socio</template>
    <template #body>
      <FormText
        label="Nombre"
        icon="fa-solid fa-user"
        v-model="form.nombre"
        disabled
      />
      <FormText
        label="Email"
        icon="fa-solid fa-envelope"
        v-model="form.email"
        disabled
      />
      <FormText
        label="Telefono"
        icon="fa-solid fa-mobile"
        v-model="form.telefono"
        disabled
      />
      <FormText
        label="Fax"
        icon="fa-solid fa-phone"
        v-model="form.fax"
        disabled
      />
      <FormDate
        label="Creado"
        icon="fa-solid fa-calendar"
        v-model="form.created_at"
        disabled
      />
      <FormDate
        label="Actualizado"
        icon="fa-solid fa-calendar"
        v-model="form.updated_at"
        disabled
      />
    </template>
  </vue-modal>
</template>
<script>
import FormText from "@/components/Form/FormText.vue";
import FormDate from "@/components/Form/FormDate.vue";
export default {
  components: {
    FormText,
    FormDate
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: null,
  }),
  mounted() {
    if (this.item) {
      this.form = {...this.item};
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>
<style></style>
