<template>
  <div class="card-body d-flex flex-row justify-content-around">
    <template v-if="machos?.length && hembras?.length">
      <div class="d-flex flex-column w-100" dir="rtl">
        <h5 class="text-center text-male">Machos <i class="fa-solid fa-mars text-male"></i></h5>
        <div class="overflow-auto needScroll">
          <div v-for="(macho, index) of machos" :key="index">
          <a class="misPerros" style="cursor: pointer" @click="$emit('sendIndexMacho',$event ,index)" dir="ltr">
            <template v-if="macho.apellido">
              <div class="card m-2">
                <div class="card-body text-center">
                  <p><i class="fa-solid fa-dog"></i> {{nombreApellido =macho.nombre+' '+macho.apellido}}</p>
                </div>
              </div>
            </template>
            <template v-else>
              <FormText
                label="Nombre"
                icon="fa-solid fa-dog"
                v-model="macho.nombre"
                disabled
              />
            </template>
          </a>
        </div>
        </div>
      </div>
      <div class="d-flex flex-column w-100">
        <h5 class="text-center text-female"><i class="fa-solid fa-venus"></i> Hembras</h5>
        <div class="overflow-auto needScroll">
          <div v-for="(hembra, index) of hembras" :key="index">
            <a class="misPerros" style="cursor: pointer" @click="$emit('sendIndexHembra',$event ,index)" >
              <template v-if="hembra.apellido">
                <div class="card m-2">
                  <div class="card-body text-center">
                    <p><i class="fa-solid fa-dog"></i> {{nombreApellido =hembra.nombre+' '+hembra.apellido}}</p>
                  </div>
                </div>
              </template>
              <template v-else>
                <FormText
                  label="Nombre"
                  icon="fa-solid fa-dog"
                  v-model="hembra.nombre"
                  disabled
                />
              </template>
            </a>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="machos?.length && !hembras?.length">
      <div class="d-flex flex-column w-100">
        <h5 class="text-center text-male"><i class="fa-solid fa-mars text-male"></i> Machos</h5>
        <div class="overflow-auto needScroll">
          <div v-for="(macho, index) of machos" :key="index">
            <a class="misPerros" style="cursor: pointer" @click="$emit('sendIndexMacho',$event ,index)" >
              <template v-if="macho.apellido">
                <div class="card m-2">
                  <div class="card-body text-center">
                     <p><i class="fa-solid fa-dog"></i> {{nombreApellido =macho.nombre+' '+macho.apellido}}</p>
                  </div>
                </div>
              </template>
              <template v-else>
                <FormText
                  label="Nombre"
                  icon="fa-solid fa-dog"
                  v-model="macho.nombre"
                  disabled
                />
              </template>
            </a>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="!machos?.length && hembras?.length">
      <div class="d-flex flex-column w-100">
        <h5 class="text-center text-female"><i class="fa-solid fa-venus"></i> Hembras</h5>
        <div class="overflow-auto needScroll">
          <div v-for="(hembra, index) of hembras" :key="index">
            <a class="misPerros" style="cursor: pointer" @click="$emit('sendIndexHembra',$event ,index)" >
              <template v-if="hembra.apellido">
                <div class="card m-2">
                  <div class="card-body text-center">
                     <p><i class="fa-solid fa-dog"></i> {{nombreApellido =hembra.nombre+' '+hembra.apellido}}</p>
                  </div>
                </div>
              </template>
              <template v-else>
                <FormText
                  label="Nombre"
                  icon="fa-solid fa-dog"
                  v-model="hembra.nombre"
                  disabled
                />
              </template>
            </a>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="d-flex flex-column w-100">
        <h5 class="text-center"> No Posee Perros</h5>
      </div>
    </template>
  </div>

</template>

<script>
import FormText from "@/components/Form/FormText.vue";
export default {
  components:{
    FormText,
  },
  name: "DogsComponent",

  computed: {
    machos(){
      return this.$store.getters.canesM
    },
    hembras(){
      return this.$store.getters.canesF
    }
  },
  methods:{
  },
};
</script>

<style>
a{
  text-decoration: none !important; 
}
</style>