<template>
  <Widget class="h-auto">
    <template #title>Delegacion</template>
    <div class="card">
      <div class="card-body">
        <h2><i class="fa-solid fa-clipboard-list"></i> Socios</h2>
        <vue-table
          v-if="vTable.headers"
          :values="item"
          :headers="vTable.headers"
          :options="vTable.options"
          :actions="vTable.actions"
          @changed="getItems"
          @onShowItem="onShowItem"
          @onShowItemDog="onShowItemDog"
          ref="vtable"
          :empty_message="`No hay Personas`"
        />
      </div>
    </div>
  </Widget>
  <PersonaDogsTable 
    v-if="onShowDogModal"
    @close="closeModal"
    :item="selItem"
    @saved="onSaveItem"
    />
    <PersonaModal 
    v-if="showModal"
    @close="closeModal"
    :item="selItem"
    @saved="onSaveItem"
    />
</template>
<script>
import Widget from "@/components/Widget.vue";
import PersonaDogsTable from "./UtilsDashboard/PersonaDogsTable.vue"
import PersonaModal from "./UtilsDashboard/PersonaModal.vue"

export default {
  name: "DashboardDelegacion",
  components: {
    Widget,
    PersonaDogsTable,
    PersonaModal,
  },
  data: () =>({
    vTable: {
      headers: [
        {
          title: "id",
          mask: "ID",
          sortable: true,
        },
        {
          title: "nombre",
          mask: "Nombre",
          sortable: true,
        },
        {
          title: "email",
          mask: "email",
           sortable: true,
        },
        {
          title: "telefono",
          mask: "telefono",
          sortable: true,
        },
        {
          title: "fax",
          mask: "fax",
          sortable: true,
        },
      ],
      actions: [
        {
          buttonClass: "btn-info",
          callback: "onShowItem",
          tooltip: "Mostrar Detalles",
          icon: "fa-solid fa-eye",
        },
        {
          buttonClass: "btn-primary",
          callback: "onShowItemDog",
          tooltip: "Ver Perros",
          icon: "fa-solid fa-dog",
        },
      ],
      options: {
        /// Go to OPTIONS SECTION for explanation
      },
    },
    showModal: false,
    onShowDogModal: false,
    selItem: null,
  }),
  computed: {
    item(){
      if (!this.$store.getters.listaPersonas) return []
      return this.$store.getters.listaPersonas
    }
  },
  async beforeMount() {
    if(this.$store.getters.user){
      await this.obtenerPersonas();
      await this.initializeTable();
    }
    
  },
  methods: {
    closeModal() {
      this.selItem = null;
      this.showModal = false;
      this.onShowDogModal = false;
    },
    getItems(params) {
      this.$store.dispatch("getListaPersonas", params);
    },
    obtenerPersonas(){
       this.$store.dispatch('getListaPersonas');
    },
    initializeTable() {
      this.$refs.vtable.init();
    },
    onSaveItem() {
      this.initializeTable(); // Reset table
    },
    onShowItem(item) {
      this.showModal = true;
      this.selItem = item;
    },
    onShowItemDog(item) {
      this.onShowDogModal = true;
      this.selItem = item;
    },
  },
};
</script>

<style>

</style>
